<template>
  <div class="container-fluid">
    <div class="box__title">
      <span class="fs-4">Users</span>
      <button @click="showModal()" class="btn btn-primary btn-sm add-user-button">
        Add
      </button>
    </div>
    <div class="box__content">
      <div class="users__table">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Toggl User ID</th>
            <th scope="col">Toggle Username</th>
            <th scope="col">Jira Email</th>
            <th scope="col">Jira Token</th>
            <th scope="col">Min Start Date</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in users" :key="item.togglUserId">
            <th scope="row">{{ item.togglUserId }}</th>
            <td>{{ item.togglUserName }}</td>
            <td>{{ item.jiraEmail }}</td>
            <td>{{ item.jiraToken }}</td>
            <td>{{ formatDate(item.minStartDate) }}</td>
            <td>
              <button @click="editUserClick(item)"
                      class="btn btn-light btn-sm users__action-item"><i class="far fa-edit"></i>
              </button>
              <button @click="removeUserClick(item.togglUserId)"
                      class="btn btn-danger btn-sm users__action-item"><i class="far fa-trash-alt"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="addUserModal" ref="addUserModal" tabindex="-1" aria-labelledby="addUserModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addUserModalLabel">{{editMode ? 'Edit User' : 'Add User' }}</h5>
          <button @click="hideModal()" type="button" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit="submitUser">
            <div v-if="!editMode" class="mb-3">
              <label class="form-label">Toggl User ID</label>
              <input v-model="userItem.togglUserId" type="number" required class="form-control"/>
            </div>
            <div class="mb-3">
              <label class="form-label">Toggl Username</label>
              <input v-model="userItem.togglUserName" type="text" required class="form-control"/>
            </div>
            <div class="mb-3">
              <label class="form-label">Jira email</label>
              <input v-model="userItem.jiraEmail" required type="text" class="form-control"/>
            </div>
            <div class="mb-3">
              <label class="form-label">Jira token</label>
              <input v-model="userItem.jiraToken" required type="text" class="form-control"/>
            </div>
            <div class="mb-3">
              <label class="form-label">Min start date</label>
              <datepicker v-model="userItem.minStartDate" inputFormat="MM/dd/yyyy" class="form-control"/>
            </div>
            <button type="submit" class="btn btn-primary">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.users__table {
  margin-top: 1rem;
}

.add-user-button {
  margin-left: 1rem;
}

.users__action-item + .users__action-item {
  margin-left: 1rem;
}
</style>

<script>
import {mapActions, mapState} from "vuex";
import dayjs from "dayjs";
import Datepicker from 'vue3-datepicker';

export default {
  data() {
    return {
      addUserModal: null,
    }
  },
  components: {
    Datepicker
  },
  computed: {
    ...mapState('users', [
      'users',
      'userItem',
      'isOpen',
      'editMode'
    ]),
  },
  watch: {
    isOpen: 'isOpenChanged'
  },
  methods: {
    ...mapActions('users', [
      'loadUsers',
      'saveUser',
      'removeUser',
      'showModal',
      'hideModal'
    ]),
    submitUser(e) {
      e.preventDefault();
      this.saveUser(this.userItem);
    },
    editUserClick(user) {
      this.showModal({
        user: {...user, minStartDate: user.minStartDate && new Date(user.minStartDate)},
        editMode: true
      });
    },
    removeUserClick(userId) {
      if (confirm("really?")) {
        this.removeUser(userId);
      }
    },
    isOpenChanged(isOpen) {
      if (!this.addUserModal) {
        this.addUserModal = new window.bootstrap.Modal(this.$refs.addUserModal);
        this.$refs.addUserModal.addEventListener('hidden.bs.modal', () => {
          this.hideModal();
        });
      }

      if (isOpen) {
        this.addUserModal.show();
      } else {
        this.addUserModal.hide();
      }
    },
    formatDate(dateString) {
      if (!dateString) {
        return;
      }

      return dayjs(dateString).format('MM/D/YYYY');
    },
  },
  created() {
    this.loadUsers();
  }
}
</script>
